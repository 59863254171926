import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { calendarLabels } from '../../helpers/calendarLabels';
import { uiOpenModal } from '../../actions/ui';
import {
    clearActiveEvent,
    eventSetActive,
    eventStartLoading,
} from '../../actions/events';
import Navbar from '../ui/Navbar';
import AddNewFab from '../ui/AddNewFab';
import DeleteEventFab from '../ui/DeleteEventFab';
import CalendarEvent from './CalendarEvent';
import CalendarModal from './CalendarModal';

const localizer = momentLocalizer(moment);

const CalendarScreen = () => {
    const { events, activeEvent } = useSelector(state => state.calendar);
    const { uid } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [lastView, setLastView] = useState(
        localStorage.getItem('lastView') || 'month'
    );

    useEffect(() => {
        dispatch(eventStartLoading());
    }, []);

    const onDoubleClick = event => {
        dispatch(uiOpenModal());
    };

    const onSelectEvent = event => {
        dispatch(eventSetActive(event));
    };

    const onViewChange = view => {
        localStorage.setItem('lastView', view);
        setLastView(view);
    };

    const eventStylerGetter = (event, start, end, isSelected) => {
        const style = {
            backgroundColor: uid === event.user._id ? '#367cf7' : '#465660',
            borderRadius: '0',
            opacity: 0.8,
            display: 'block',
            color: 'white',
        };

        return {
            style,
        };
    };

    const handleSelectSlot = event => {
        dispatch(clearActiveEvent());
    };

    return (
        <div className="calendar-screen">
            <Navbar />

            <Calendar
                messages={calendarLabels}
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                // style={{ height: 500 }}
                view={lastView}
                onDoubleClickEvent={onDoubleClick}
                onSelectEvent={onSelectEvent}
                onView={onViewChange}
                eventPropGetter={eventStylerGetter}
                selectable={true}
                onSelectSlot={handleSelectSlot}
                components={{
                    event: CalendarEvent,
                }}
            />

            <AddNewFab />

            {activeEvent && <DeleteEventFab />}

            <CalendarModal />
        </div>
    );
};

export default CalendarScreen;
