import Swal from 'sweetalert2';

import { fetchWithoutToken, fetchWithToken } from '../helpers/fetch';
import { types } from '../types/types';
import { eventLogout } from './events';

export const startLogin = (email, password) => {
    // this is necessary because is an async task
    return async dispatch => {
        // dispatch is provide by redux-thunk
        const response = await fetchWithoutToken(
            'auth/login',
            { email, password },
            'POST'
        );
        const body = await response.json();

        if (!body.error) {
            localStorage.setItem('calendar_token', body.token);
            localStorage.setItem(
                'calendar_token_init_date',
                new Date().getTime()
            );

            dispatch(login({ uid: body.user._id, name: body.user.name }));
        } else {
            Swal.fire('Error', body.message, 'error');
        }
    };
};

const login = user => ({
    type: types.authLogin,
    payload: user,
});

export const startRegister = (email, password, name) => {
    return async dispatch => {
        const response = await fetchWithoutToken(
            'auth/register',
            { email, password, name },
            'POST'
        );
        const body = await response.json();

        if (body.error) {
            return Swal.fire('Error', body.message, 'error');
        }

        localStorage.setItem('calendar_token', body.token);
        localStorage.setItem('calendar_token_init_date', new Date().getTime());

        dispatch(login({ uid: body.user._id, name: body.user.name }));
    };
};

export const startChecking = () => {
    return async dispatch => {
        const response = await fetchWithToken('auth/renew-token');
        const body = await response.json();

        if (!body.error) {
            localStorage.setItem('calendar_token', body.token);
            localStorage.setItem(
                'calendar_token_init_date',
                new Date().getTime()
            );

            dispatch(login({ uid: body.user.uid, name: body.user.name }));
        } else {
            dispatch(checkingFinish());
        }
    };
};

const checkingFinish = () => ({
    type: types.authCheckingFinish,
});

export const startLogout = () => {
    return dispatch => {
        localStorage.setItem('calendar_token_init_date', '');
        localStorage.setItem('calendar_token', '');

        dispatch(logout());
        dispatch(eventLogout());
    };
};

const logout = () => ({
    type: types.authLogout,
});
