import { types } from '../types/types';

const initialState = {
    events: [],
    activeEvent: null,
};

export const calendarReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.eventSetActive:
            return {
                ...state,
                activeEvent: payload,
            };

        case types.eventAddNew:
            return {
                ...state,
                events: [...state.events, payload],
            };

        case types.eventClearActive:
            return {
                ...state,
                activeEvent: null,
            };

        case types.eventUpdate:
            return {
                ...state,
                events: state.events.map(event => {
                    return event.id === payload.id ? payload : event;
                }),
            };

        case types.eventDelete:
            return {
                ...state,
                events: state.events.filter(event => {
                    return event.id !== state.activeEvent.id;
                }),
                activeEvent: null,
            };

        case types.eventsLoaded:
            return { ...state, events: [...payload] };

        case types.eventLogout:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};
