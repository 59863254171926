export const calendarLabels = {
    allDays: 'All day',
    previous: '<',
    next: '>',
    today: 'Today',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    agenda: 'Agenda',
    date: 'Date',
    time: 'Time',
    event: 'Event',
    noEventsInRange: 'No events in range',
    showMore: total => `+ See more (${total})`,
};
