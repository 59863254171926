export const types = {
    uiOpenModal: '[ui] Open modal',
    uiCloseModal: '[ui] Close modal',

    eventSetActive: '[event] Set active event',
    eventClearActive: '[event] Clear active event',
    eventStartAddNew: '[event] Start add new event',
    eventAddNew: '[event] Add new event',
    eventUpdate: '[event] Update event',
    eventDelete: '[event] Delete event',
    eventsLoaded: '[event] Events loaded',
    eventLogout: '[event] Clear event state',

    authCheckingFinish: '[auth] Finish checking login state',
    authStartLogin: '[auth] Start login',
    authLogin: '[auth] Login',
    authStartRegister: '[auth] Start register',
    authRegister: '[auth] Register',
    authStartRenewToken: '[auth] Start renew token',
    authLogout: '[auth] Logout',
};
