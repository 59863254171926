import React from 'react';
import { useDispatch } from 'react-redux';

import { eventStartDelete } from '../../actions/events';

const DeleteEventFab = () => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(eventStartDelete());
    };

    return (
        <button className="btn btn-danger fab-danger" onClick={handleClick}>
            <i className="fas fa-trash"></i>
        </button>
    );
};

export default DeleteEventFab;
