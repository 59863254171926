import { types } from '../types/types';
import { fetchWithToken } from '../helpers/fetch';
import Swal from 'sweetalert2';
import { prepareEvents } from '../helpers/prepareEvents';

export const eventStartLoading = () => {
    return async dispatch => {
        const response = await fetchWithToken('events');
        const body = await response.json();

        if (body.error) {
            return Swal.fire('Error', body.message, 'error');
        }

        dispatch(eventsLoaded(prepareEvents(body.events)));
    };
};

const eventsLoaded = events => ({
    type: types.eventsLoaded,
    payload: events,
});

export const eventStartAddNew = event => {
    return async (dispatch, getState) => {
        const { uid, name } = getState().auth;

        const response = await fetchWithToken('events', event, 'POST');
        const body = await response.json();

        if (body.error) {
            return Swal.fire('Error', body.message, 'error');
        }

        dispatch(
            eventAddNew({
                ...event,
                id: body.event.id,
                user: { _id: uid, name },
            })
        );
    };
};

const eventAddNew = event => ({
    type: types.eventAddNew,
    payload: event,
});

export const eventSetActive = event => ({
    type: types.eventSetActive,
    payload: event,
});

export const clearActiveEvent = () => ({
    type: types.eventClearActive,
});

export const eventStartUpdate = event => {
    return async dispatch => {
        const response = await fetchWithToken(
            `events/${event.id}`,
            event,
            'PUT'
        );
        const body = await response.json();

        if (body.error) {
            return Swal.fire('Error', body.message, 'error');
        }

        dispatch(eventUpdate(event));
    };
};

const eventUpdate = event => ({
    type: types.eventUpdate,
    payload: event,
});

export const eventStartDelete = () => {
    return async (dispatch, getState) => {
        const { activeEvent } = getState().calendar;

        const response = await fetchWithToken(
            `events/${activeEvent.id}`,
            {},
            'DELETE'
        );
        const body = await response.json();

        if (body.error) {
            return Swal.fire('Error', body.message, 'error');
        }

        dispatch(eventDelete());
    };
};

const eventDelete = () => ({
    type: types.eventDelete,
});

export const eventLogout = () => ({
    type: types.eventLogout,
});
