import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import Swal from 'sweetalert2';

import { uiCloseModal } from '../../actions/ui';
import {
    clearActiveEvent,
    eventStartAddNew,
    eventStartUpdate,
} from '../../actions/events';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%,-50%)',
    },
};

if (process.env.NODE_ENV !== 'test') {
    Modal.setAppElement('#root');
}

const initEvent = {
    title: '',
    notes: '',
    start: moment().minutes(0).seconds(0).add(1, 'hours').toDate(),
    end: moment().minutes(0).seconds(0).add(2, 'hours').toDate(),
};

const CalendarModal = () => {
    const { modalOpen } = useSelector(state => state.ui);
    const { activeEvent } = useSelector(state => state.calendar);

    const dispatch = useDispatch();

    const [titleIsValid, setTitleIsValid] = useState(true);

    const [formValues, setFormValues] = useState(initEvent);

    const { title, notes, start, end } = formValues;

    useEffect(() => {
        if (activeEvent) {
            setFormValues(activeEvent);
        } else {
            setFormValues(initEvent);
        }
    }, [activeEvent]);

    const closeModal = () => {
        dispatch(uiCloseModal());
        dispatch(clearActiveEvent());
        setFormValues(initEvent);
    };

    const handleStartDateChange = date => {
        setFormValues({
            ...formValues,
            start: date,
        });
    };

    const handleEndDateChange = date => {
        setFormValues({
            ...formValues,
            end: date,
        });
    };

    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value,
        });
    };

    const handleSubmitForm = event => {
        event.preventDefault();

        const momentStart = moment(start);
        const momentEnd = moment(end);

        if (momentStart.isSameOrAfter(momentEnd)) {
            Swal.fire(
                'Error',
                'The end date must be after the start date',
                'error'
            );
            return;
        }

        if (title.trim().length < 2) {
            setTitleIsValid(false);
            return;
        }

        if (activeEvent) {
            dispatch(eventStartUpdate(formValues));
        } else {
            dispatch(
                eventStartAddNew({
                    ...formValues,
                })
            );
        }

        setTitleIsValid(true);
        closeModal();
    };

    return (
        <Modal
            isOpen={modalOpen}
            // onAfterOpen={after0penModal}
            onRequestClose={closeModal}
            closeTimeoutMS={200}
            style={customStyles}
            className="modal"
            overlayClassName="modal-fondo"
            ariaHideApp={!process.env.NODE_ENV === 'test'}
        >
            <h1> {activeEvent ? 'Edit' : 'New'} Event </h1>
            <hr />
            <form className="container" onSubmit={handleSubmitForm}>
                <div className="form-group">
                    <label>Start date and time</label>
                    <DateTimePicker
                        placeholder="Start date"
                        className="form-control"
                        name="startDate"
                        onChange={handleStartDateChange}
                        value={start}
                    />
                </div>

                <div className="form-group">
                    <label>End date and time</label>
                    <DateTimePicker
                        placeholder="End date"
                        className="form-control"
                        name="endDate"
                        onChange={handleEndDateChange}
                        value={end}
                        minDate={start}
                    />
                </div>

                <hr />
                <div className="form-group">
                    <label>Title and notes</label>
                    <input
                        type="text"
                        className={`form-control ${
                            !titleIsValid && 'is-invalid'
                        }`}
                        placeholder="Event title"
                        name="title"
                        autoComplete="off"
                        value={title}
                        onChange={handleInputChange}
                    />
                    <small id="emailHelp" className="form-text text-muted">
                        A short description
                    </small>
                </div>

                <div className="form-group">
                    <textarea
                        type="text"
                        className="form-control"
                        placeholder="Notes"
                        rows="5"
                        name="notes"
                        value={notes}
                        onChange={handleInputChange}
                    ></textarea>
                    <small id="emailHelp" className="form-text text-muted">
                        Additional Information
                    </small>
                </div>

                <button
                    type="submit"
                    className="btn btn-outline-primary btn-block"
                >
                    <i className="far fa-save"></i>
                    <span> Save</span>
                </button>
            </form>
        </Modal>
    );
};

export default CalendarModal;
